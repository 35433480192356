export const config = {
  //server

  // old
  // serverURL: "http://144.76.103.235:9098/",
  // API: "http://144.76.103.235:9098/admin/api",
  // publicAPI: "http://144.76.103.235:9098/api",
  // ws: "ws://144.76.103.235:9099",

  // new
  serverURL: "https://kuhenchef.kitg.com.ua/",
  API: "https://kuhenchef.kitg.com.ua/admin/api",
  publicAPI: "https://kuhenchef.kitg.com.ua/api",
  ws: "ws://144.76.103.235:9099",

  //локалка

  // serverURL: 'http://144.76.103.235:8078/',
  // API: 'http://144.76.103.235:8078/admin/api',
  // publicAPI: 'http://144.76.103.235:8078/api',
  // ws: 'ws://144.76.103.235:8079',

  ///демка
  //
  // serverURL: 'http://144.76.103.235:8071/',
  // API: 'http://144.76.103.235:8071/admin/api',
  // publicAPI: 'http://144.76.103.235:8071/api',
  // ws: 'ws://144.76.103.235:8072',

  // ws: 'ws://127.0.0.1:8082',
  // serverURL: 'http://127.0.0.1:8080/',
  // API: 'http://127.0.0.1:8081/admin/api',
};
