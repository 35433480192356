import React, { Fragment, useContext } from "react";

import * as Contexts from "../../contexts";
import * as Components from ".";

const Bar: React.FC = () => {
  // const { isConnected, messages } = useContext(Contexts.WSContext)
  // console.log("messages",messages);
  return (
    <div className="notif-bar">
      {/* {!isConnected && <Components.Disconnected />}

      {messages.map((message: any) => (
        <Components.Item
          key={message._id}
          data={message}
        />
      ))} */}
    </div>
  );
};

export default Bar;
